const pageList = [
    {
        id: 1,
        name: 'About',
        link: '',
        url: '#About'
    },
    {
        id: 2,
        name: 'Portfolio',
        link: 'work',
        url: '#Portfolio'
    },
    {
        id: 3,
        name: 'Contact',
        link: 'contact',
        url: '#Contact'
    },
    {
        id: 4,
        name: 'Resume',
        link: 'resume',
        url: '#Resume'
    },
];

export default pageList;